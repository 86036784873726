import React, { Component } from 'react';
import {isMobile} from 'react-device-detect';
import '../App.css';
import '../AppMobile.css';

export default class Home extends Component {

    render(){
        
        return(
            <div id={"home" + (isMobile ? "Mobile" : "")}>
               <img src="http://sylviefayon-dessinsgravures.com/img/home/m2.jpg" alt="accueil"></img>
               <div id={"homeText"+ (isMobile ? "Mobile" : "")}>
                    <p>
                        Diplômée de l’École Nationale des Beaux Arts de Paris 
                    </p>
                    <ul>
                        <li>atelier de peinture de Claude Augereau</li>
                        <li>atelier de dessin de Marcel Gili</li>
                        <li>atelier de gravure de Jacques Lagrange et Bertrand Dorny</li>
                    </ul>
                    Lauréate du Prix David Weil
                    <br/>
                    Travaille à l’atelier de gravure de la Cité Internationale des Arts de Paris
                    <br/>
                    <br/>
                    “Je dessine beaucoup à l’extérieur. Ces carnets deviennent la matière
                    de mes dessins et de mes gravures.
                    Ils constituent mon univers de travail personnel.” 
                    <p></p>
                </div>
            </div>
        );
    }
}