const request = require('superagent');


export default class ActuController{
    getActus(callback){
        request.get("https://sylviefayon-dessinsgravures.com/rest/getActiveActus.php").end((err, res) =>
        {
            if(!err){
                try{
                    let json = JSON.parse(res.text);
                    callback(null, json);
                }catch(exception){
                    callback(1, null);
                }
            }
            else{
                callback(err, null);
            }
        });
    }
}