import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../App.css';


export default class Menu extends Component {
    onLinkSelected(event){
        let activeLinks = document.getElementsByClassName("activeLink");
        if(activeLinks){
            for(let i = 0; i< activeLinks.length; ++i)
                activeLinks[i].className = "passiveLink";
        }

        event.target.className = "activeLink";
    }

    render(){
        let categories = [];
       
        //Sylvie Fayon
        categories.push(<li><b>Sylvie Fayon</b></li>);
        categories.push(<li></li>);
        //home
        categories.push(
            <li key="home">
                <Link id="homeLink" className="activeLink" onClick={this.onLinkSelected.bind(this)} to="/">Accueil</Link>
            </li>);

        //Categories
        for(let i = 0; i < this.props.categories.length; ++i){
            //Replace spaces with -
            let title = this.props.categories[i].title.replace(/ /g, "-").replace(':', '');
            categories.push(
            <li key={this.props.categories[i].id}>
                <Link className="passiveLink" onClick={this.onLinkSelected.bind(this)} to={{ pathname : "/categorie/" + title, state : {categoryId : this.props.categories[i].id}}}>{this.props.categories[i].title}</Link>
            </li>);
        }

        //expos
        categories.push(
            <li key="expos">
                <Link className="passiveLink" onClick={this.onLinkSelected.bind(this)} to="/Expositions">Expositions</Link>
            </li>);

        //actus
        categories.push(
            <li key="actus">
                <Link className="passiveLink" onClick={this.onLinkSelected.bind(this)} to="/Actualités">Actualités</Link>
            </li>);

        //Contact
        categories.push(<li></li>);
        categories.push(<li></li>);
        categories.push(<li><b>Contact</b></li>);
        categories.push(<li>sylvie.fayon@sfr.fr</li>);

        return(
            <ul  id="menu">
                {categories}
            </ul>
        );
    }
}