import React, { Component } from 'react';
import { HashRouter, Route } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import CategoryController from '../controllers/CategoryController.js';
import ExpoController from '../controllers/ExpoController.js';
import ActuController from '../controllers/ActuController.js';
import Home from './Home.js';
import Menu from './Menu.js';
import MenuMobile from './MenuMobile.js';
import Category from './Category.js';
import Expos from './Expos.js';
import Actus from './Actus.js';
import '../App.css';
import '../AppMobile.css';


class App extends Component {
  constructor(){
    super();
    this.categoryController = new CategoryController();
    this.expoController = new ExpoController();
    this.actuController = new ActuController();
    this.state = {
      categories : [],
      works : [],
      expos : [],
      actus: []
    };
  }

  componentDidMount(){
    this.categoryController.getCategories((err, categories) => {
      if(!err){
        this.categoryController.getWorks((err, works) =>{
          if(!err){
            this.setState({categories : categories, works : works});
          }
        });
       
      }
    });

    this.expoController.getExpos((err, expos) =>{
      if(!err){
        this.setState({expos : expos});
      }
    });

    this.actuController.getActus((err, actus) =>{
      if(!err){
        this.setState({actus : actus});
      }
    });
  }

  getMobileContent(){
    return ( <div id="mainContainer">
    <MenuMobile  categories={this.state.categories} works={this.state.works}/>
    <div id="contentMobile">
      <Route exact path="/" component={Home} />
      <Route exact path="/Expositions" render={props => (<Expos expos={this.state.expos}/>)} />
      <Route exact path="/Actualités" render={props => (<Actus actus={this.state.actus}/>)} />
      <Route path="/categorie/:name" render={props => (<Category works={this.state.works} history={props.history}/>)} />
    </div>
  </div>);
  }

  getDesktopContent(){
    return ( <div id="mainContainer">
    <Menu  categories={this.state.categories} works={this.state.works}/>
    <div id="content">
      <Route exact path="/" component={Home} />
      <Route exact path="/Expositions" render={props => (<Expos expos={this.state.expos}/>)} />
      <Route exact path="/Actualités" render={props => (<Actus actus={this.state.actus}/>)} />
      <Route path="/categorie/:name" render={props => (<Category works={this.state.works} history={props.history}/>)} />
    </div>
  </div>);
  }

  render() {
    return (
      <div>
        <HashRouter basename="/">
          { this.getDesktopContent()}
        </HashRouter>
      </div>
    );
  }
}

export default App;
