import React, { Component } from 'react';
import '../App.css';
import '../AppMobile.css';


export default class Actus extends Component {
    render(){
        let actus = [];
        if(this.props.actus){
            for(let i = 0; i < this.props.actus.length; ++i){
                let actu = this.props.actus[i];
                actus.push(
                    <img key={actu.id} alt="" src={"http://site-sylvie2/" + actu.img}></img>);
            }
        }

        return(
            <div id="actus">
                {actus}
            </div>
        );
    }
}