import React, { Component } from 'react';
import { Redirect } from 'react-router'
import '../App.css';
import '../AppMobile.css';


export default class Category extends Component {

    constructor(){
        super();
        this.state = {
            id : -1,
            redirect: false
        };
    }

    static getDerivedStateFromProps(props, state){
        if(!props.history.location.state)
          state.redirect = true;
        else
          state.redirect = false;

        if(props.history.location.state && props.history.location.state.categoryId !== state.id){
            state.id = props.history.location.state.categoryId;
        }

        return state;
    }

    render(){
        let works = [];

        if(this.state.redirect)
            return (<Redirect to="/" />);

        if(this.props.works){
            for(let i = 0; i < this.props.works.length; ++i){
                let work = this.props.works[i];
                if(work.category === this.state.id){
                    works.push(
                        <div key={work.id} >
                    <img src={"http://sylviefayon-dessinsgravures.com/img/" + work.url} alt={work.title}></img>
                    </div>);
                }
            }
        }

        return(
            <div id="category">
                {works}
            </div>
        );
    }
}