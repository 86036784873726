const request = require('superagent');


export default class CategoryController{
    getCategories(callback){
        request.get("https://sylviefayon-dessinsgravures.com/rest/getCategories.php").end((err, res) =>
        {
            if(!err){
                try{
                    let json = JSON.parse(res.text);
                    callback(null, json);
                }catch(exception){
                    callback(1, null);
                }
                
            }
            else{
                callback(err, null);
            }
        });
    }

    getWorks(callback){
        request.get("https://sylviefayon-dessinsgravures.com/rest/getWorks.php").end((err, res) =>
        {
            if(!err){
                try{
                    let json = JSON.parse(res.text);
                    callback(null, json);
                }catch(exception){
                    callback(1, null);
                }
                
            }
            else{
                callback(err, null);
            }
        });
    }
}