import React, { Component } from 'react';
import '../App.css';
import '../AppMobile.css';


export default class Expos extends Component {
    render(){
        let expos = [];
        if(this.props.expos){
            for(let i = 0; i < this.props.expos.length; ++i){
                let expo = this.props.expos[i];
                    expos.push(
                        <li key={expo.id} >{expo.content}</li>);
            }
        }

        return(
            <div id="expos">
                <img src="http://sylviefayon-dessinsgravures.com/img/expos1.jpg" alt="accueil"></img>
                <ul>
                {expos}
                </ul>
            </div>
        );
    }
}